import './styles.scss';
import { useCallback, useState } from 'react';
import ArrowRightIcon from '../../assets/icon/ArrowRight.svg';
import ArrowDownIcon from '../../assets/icon/ArrowDown.svg';
import TickIcon from '../..//assets/icon/Tick.svg';
import CloseIcon from '../../assets/icon/Close.svg';
import LogoEco from '../../assets/icon/logo.svg';
import LogoFooter from '../../assets/icon/logo-footer.svg';
import SettingIcon from '../../assets/icon/SettingRed.svg';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { ROUTES } from 'src/routes/routes';
import React from 'react';
import { LogOut } from '@hitachivantara/uikit-react-icons';
import { ApiRequest } from 'src/api';
import useStoreGlobal from 'src/hooks/useStoreGlobal';
import { PATH } from 'src/services/Path';
import { REQUEST_METHOD, NOTIFY_TYPE } from 'src/utils/constants/enums';
import { OpenToast } from 'src/utils/helpers/function';
import { renderMessageID } from 'src/utils/helpers/utils';
import useAuthContext from 'src/hooks/useAuthContext';
import AppToolTip from 'src/components/AppToolTip';
import { Typography } from 'antd';

const Landing: React.FC = () => {
  const navigate = useNavigate();
  const storeGlobal = useStoreGlobal();
  const location = useLocation();
  const auth = useAuthContext();
  const wrapperRef: any = React.useRef(null);
  const { Text } = Typography;
  const [isDrop, setIsDrop] = useState(false);
  const [avatarName, setAvatarName] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastname, setLastname] = useState('');
  const [roleName, setRoleName] = useState('');
  const [showUser, setShowUser] = useState(false);

  const urlEcoBuild = process.env.REACT_APP_URL_ECO_BUILD;
  const urlEcoSales = process.env.REACT_APP_URL_ECO_SALES;
  const [isMore, setIsMore] = useState({
    build: false,
    sales: false,
    pro: false,
  });

  const listModules = {
    eco_build: [
      {
        name: 'Site usage',
        isLicensed: true,
      },
      {
        name: 'Machinery',
        isLicensed: true,
      },
      {
        name: 'Travelling',
        isLicensed: true,
      },
      {
        name: 'Consumables',
        isLicensed: true,
      },
      {
        name: 'Shipping',
        isLicensed: true,
      },
      {
        name: 'Equipment',
        isLicensed: false,
      },
      {
        name: 'Operations',
        isLicensed: false,
      },
    ],
    eco_sales: [
      {
        name: 'Site usage',
        isLicensed: false,
      },
      {
        name: 'Machinery',
        isLicensed: false,
      },
      {
        name: 'Travelling',
        isLicensed: false,
      },
      {
        name: 'Consumables',
        isLicensed: false,
      },
      {
        name: 'Shipping',
        isLicensed: true,
      },
      {
        name: 'Equipment',
        isLicensed: true,
      },
      {
        name: 'Operations',
        isLicensed: true,
      },
    ],
    eco_pro: [
      {
        name: 'Site usage',
        isLicensed: true,
      },
      {
        name: 'Machinery',
        isLicensed: true,
      },
      {
        name: 'Travelling',
        isLicensed: true,
      },
      {
        name: 'Consumables',
        isLicensed: true,
      },
      {
        name: 'Shipping',
        isLicensed: true,
      },
      {
        name: 'Equipment',
        isLicensed: true,
      },
      {
        name: 'Operations',
        isLicensed: true,
      },
    ],
  };
  const handleClickMore = (key: string) => {
    switch (key) {
      case 'Build':
        if (isMore.build) {
          setIsMore({
            build: false,
            sales: false,
            pro: false,
          });
        } else {
          setIsMore({
            build: true,
            sales: false,
            pro: false,
          });
        }
        break;
      case 'Sales':
        if (isMore.sales) {
          setIsMore({
            build: false,
            sales: false,
            pro: false,
          });
        } else {
          setIsMore({
            build: false,
            sales: true,
            pro: false,
          });
        }
        break;
      case 'Pro':
        if (isMore.pro) {
          setIsMore({
            build: false,
            sales: false,
            pro: false,
          });
        } else {
          setIsMore({
            build: false,
            sales: false,
            pro: true,
          });
        }
        break;

      default:
        break;
    }
  };

  React.useEffect(() => {
    if (auth.isAuth) {
      let firstName = auth.userInfo?.user?.firstname ? auth.userInfo?.user?.firstname : '';
      let lastname = auth.userInfo?.user?.lastname ? auth.userInfo?.user?.lastname : '';
      let roleName = auth.userInfo?.user?.role_name ? auth.userInfo?.user?.role_name : '';

      if (firstName && lastname) {
        setAvatarName(`${firstName.charAt(0)}${lastname.charAt(0)}`);
      }
      setFirstName(firstName);
      setLastname(lastname);
      setRoleName(roleName);
      setShowUser(true);
    }

    if (!auth.isAuth) {
      setShowUser(false);
    }
  }, [location.pathname, auth.userInfo]);

  React.useEffect(() => {
    if (
      location.pathname === ROUTES.LOGIN ||
      location.pathname === ROUTES.FORGOT_PASSWORD ||
      location.pathname.includes('reset-password') ||
      location.pathname.includes('check-token-reset-password')
    ) {
      setShowUser(false);
    } else {
      setShowUser(true);
    }
  }, [location]);

  React.useEffect(() => {
    function handleClickOutside(event: any) {
      if (wrapperRef.current && !(wrapperRef.current as any).contains(event.target)) {
        setIsDrop(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const openDropLogout = () => {
    setIsDrop(true);
  };

  const logOut = useCallback(async () => {
    setIsDrop(false);
    storeGlobal.setIsLoading(true);
    const response = await ApiRequest(PATH.LOG_OUT, REQUEST_METHOD.POST, true);

    if (response?.isError) {
      OpenToast(
        {
          message: renderMessageID('MSE00075'),
        },
        NOTIFY_TYPE.ERROR,
      );
      return;
    }

    localStorage.clear();
    auth.setAuth && auth.setAuth({ ...auth, isAuth: false, userInfo: null });
    storeGlobal.setIsLoading(false);

    return navigate(ROUTES.LOGIN);
  }, []);

  return (
    <div className="App">
      <header className="header">
        <div className="inner">
          <div className="logo-link">
            <img src={LogoEco} alt="Logo" className="logo" />
            <span className="logo-brand">EcoSpace™</span>
          </div>
          {[ROUTES.HOME, ROUTES.HOME_2].includes(location?.pathname) ? (
            <AppToolTip
              placement="leftBottom"
              titleNode={
                <span className="list-item__text" style={{ color: 'rgba(0, 0, 0, 0.88)' }}>
                  Click the gear icon <br />
                  for settings to
                  <br />
                  manage account
                  <br /> access, update
                  <br />
                  your profile name,
                  <br />
                  and change your
                  <br />
                  password
                </span>
              }
              childrenNode={
                <span
                  style={{
                    cursor: 'pointer',
                    height: '45px',
                    width: '45px',
                  }}
                >
                  <img
                    style={{
                      height: '45px',
                      width: '45px',
                    }}
                    src={SettingIcon}
                    onClick={() => {
                      navigate(ROUTES.LOGIN);
                    }}
                    alt=""
                  />
                </span>
              }
            />
          ) : location?.pathname === ROUTES.LOGIN ? (
            <></>
          ) : (
            <>
              {showUser && (
                <div className="action">
                  <div className="avatar" onClick={openDropLogout}>
                    <span>{avatarName}</span>
                  </div>
                </div>
              )}
              {isDrop && (
                <div ref={wrapperRef} className="show__action blockDrop">
                  <div className="show__action-drop">
                    <div>
                      <p className="show__action-name">Username</p>
                    </div>
                    <div>
                      <p className="show__action-name-value" style={{ overflow: 'hidden' }}>
                        <span style={{ whiteSpace: 'nowrap', overflow: 'hidden' }}>
                          {roleName}, {firstName + ' ' + lastname}{' '}
                        </span>
                      </p>
                      <p className="show__action-name-value"></p>
                    </div>
                    <div className="show__action-btnLogout" onClick={logOut}>
                      <LogOut />
                      <p>Log out</p>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </header>
      <div className="container">
        <div className="content-left">
          <div className="content-left-body">
            <div className="item-project">
              <div className="title-project-name">EcoSpace™ Build</div>
              <div className="key-eco-type">eco_build</div>
              <div className="item-project-go link-eco_build">
                <img src={ArrowRightIcon} alt="" className="icon" />
                <a href={urlEcoBuild}>
                  <Text className="title-more">Go!</Text>
                </a>
              </div>
              <div className="item-project-more">
                <img src={ArrowDownIcon} alt="" className={`icon icon-more-eco_build ${isMore.build ? 'icon-more-is-active' : ''}`} />
                <p onClick={() => handleClickMore('Build')}>
                  <Text className="title-more">More</Text>
                </p>
              </div>
              <div className="module-eco_build">
                {isMore.build && (
                  <>
                    <p className="description">
                      Helps to quantify, visualize and analyze services and project execution environmental footprint.
                      <br />
                      <br /> The added value is to offer tender differentiation and operational excellence.
                    </p>
                    <ul className="list-module">
                      {listModules.eco_build.map((item: any, index: number) => {
                        return (
                          <li key={index}>
                            <img src={item?.isLicensed ? TickIcon : CloseIcon} alt="icon" className="icon-module" />
                            <span>{item?.name}</span>
                          </li>
                        );
                      })}
                    </ul>
                  </>
                )}
              </div>
            </div>
            <div className="item-project">
              <div className="title-project-name">EcoSpace™ Sales</div>
              <div className="key-eco-type">eco_sales</div>
              <div className="item-project-go link-eco_sales">
                <img src={ArrowRightIcon} alt="" className="icon" />
                <a href={urlEcoSales}>
                  <Text className="title-more">Go!</Text>
                </a>
              </div>
              <div className="item-project-more more-sales">
                <img src={ArrowDownIcon} alt="" className={`icon icon-more-eco_sales ${isMore.sales ? 'icon-more-is-active' : ''}`} />
                <p onClick={() => handleClickMore('Sales')}>
                  <Text className="title-more">More</Text>
                </p>
              </div>
              <div className="module-eco_sales">
                {isMore.sales && (
                  <>
                    <p className="description">
                      Support sales and tendering teams to engaged with customers and generate sensitivities based on previous LCAs.
                      <br />
                      <br /> The added value is to offer market differentiation and solution positioning.​
                    </p>
                    <ul className="list-module">
                      {listModules?.eco_sales.map((item: any, index: number) => {
                        return (
                          <li key={index}>
                            <img src={item?.isLicensed ? TickIcon : CloseIcon} alt="icon" className="icon-module" />
                            <span>{item?.name}</span>
                          </li>
                        );
                      })}
                    </ul>
                  </>
                )}
              </div>
            </div>
            <div className="item-project">
              <div className="title-project-name">EcoSpace™ Pro</div>
              <div className="key-eco-type">eco_pro</div>
              <div className="item-project-go link-eco_pro">
                <img src={ArrowRightIcon} alt="" className="icon" />
                <a href={'./'}>
                  <Text className="title-more">Go!</Text>
                </a>
              </div>
              <div className="item-project-more more-pro">
                <img src={ArrowDownIcon} alt="" className={`icon icon-more-eco_pro ${isMore.pro ? 'icon-more-is-active' : ''}`} />
                <p onClick={() => handleClickMore('Pro')}>
                  <Text className="title-more">More</Text>
                </p>
              </div>
              <div className="module-eco_pro">
                {isMore.pro && (
                  <>
                    <p className="description">
                      Helps to quantify, visualize and analyze full services and projects environmental footprint.
                      <br />
                      <br /> The added value is to upsell SLAs, EconiQ services…
                    </p>
                    <ul className="list-module">
                      {listModules?.eco_pro.map((item: any, index: number) => {
                        return (
                          <li key={index}>
                            <img src={item?.isLicensed ? TickIcon : CloseIcon} alt="icon" className="icon-module" />
                            <span>{item?.name}</span>
                          </li>
                        );
                      })}
                    </ul>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="content-right">
          {[ROUTES.HOME, ROUTES.HOME_2, ROUTES.LOGIN].includes(location?.pathname) && (
            <>
              <h3 className="title-describe">Advancing a Sustainable Energy Future for All</h3>
              <h2 className="title-project">EcoSpace™</h2>
            </>
          )}
          <Outlet />
        </div>
      </div>
      <footer className="footer">
        <img src={LogoFooter} alt="Logo" className="footer-img-logo" />
        <div className="footer-info">
          <span
            style={{
              color: '#414141',
            }}
            data-cy="footer-copyright"
            className="footer-copyright"
          >
            <Text>© Hitachi Corporation {new Date().getFullYear()}. All Rights Reserved.</Text>
          </span>
          <span>
            <a href="/" className="footer-license">
              <Text className="text-red">License information</Text>
            </a>
          </span>
        </div>
      </footer>
    </div>
  );
};

export default Landing;
